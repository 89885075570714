import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, useLocation, useSearchParams } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import globalFunctions from './GlobalFunctions';
import './assets/css/main.css';
import { SnackbarProvider } from 'notistack';
import { theme } from './Theme';
import { ThemeProvider } from '@mui/material/styles';

function App() {
  /*=============================================
  PREVENT DEFAULT WEBFLOW FORM SUBMISSION
  =============================================*/
  useEffect(() => {
    globalFunctions.StopWebflowDefault();

    /*=============================================
    BUILD LOADER
    =============================================*/
    globalFunctions.BuildLoader();
  }, []);
  /*=============================================*/


  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
          <Router>
            <AppRoutes />
          </Router>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
  
    <App />
  // </React.StrictMode>
);


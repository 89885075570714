import { Button, TextField } from "@mui/material";
import { ApiRequest } from "GlobalFunctions";
import { useEffect, useState } from "react";
import { CiWarning } from "react-icons/ci";
import { TfiLock } from "react-icons/tfi";
import { CiCreditCard1 } from "react-icons/ci";
import { BsCalendar2Check } from "react-icons/bs";
import { GoPerson } from "react-icons/go";


export default function DefaultForm(props: any) {

    const { 
        payment_method,
        setPaymentStatus,
        enqueueSnackbar,
        ShowLoader
} = props;

console.log(payment_method);

    const [formValues, setFormValues] = useState({
        name: "",
        card_number: "",
        card_expiration: "",
        card_cvv: "",
    });
    const [errors, setErrors] = useState({
        card_expiration: false,
    });



    const formatCardNumber = (value: string) => {
        return value
            .replace(/\D/g, '') // Eliminar cualquier carácter que no sea un dígito
            .replace(/(.{4})/g, '$1 ') // Añadir un espacio después de cada 4 dígitos
            .trim() // Eliminar cualquier espacio final extra
            .substring(0, 19); // Limitar el total de caracteres a 19 (16 dígitos + 3 espacios)
    };

    const formatExpirationDate = (value: string) => {
        let cleanedValue = value.replace(/\D/g, '');

        if (cleanedValue.length > 2) {
            cleanedValue = cleanedValue.substring(0, 2) + '/' + cleanedValue.substring(2);
        }

        return cleanedValue.substring(0, 5);
    };

    const handleExpirationChange = (e: any) => {
        const formattedValue = formatExpirationDate(e.target.value);
        setFormValues({ ...formValues, card_expiration: formattedValue });

        validateExpirationDate(formattedValue);
    };

    const validateExpirationDate = (value: string) => {
        const [month, year] = value.split('/').map(Number);
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1; // Los meses son 0-indexados
        const currentYear = currentDate.getFullYear() % 100; // Obtener los últimos dos dígitos del año

        let isValid = true;

        // Validar que el mes no sea superior a 12
        if (month > 12 || month < 1) {
            isValid = false;
        }

        // Validar que la fecha sea superior a la presente
        if (year < currentYear || (year === currentYear && month < currentMonth)) {
            isValid = false;
        }

        setErrors({ ...errors, card_expiration: !isValid });
    };


    const submitPayment = async () => {
        
        if (!formValues.name || formValues.name.length < 2) {
            enqueueSnackbar('Please enter a valid name', { variant: 'error' });
            return;
        }

        if (!formValues.card_number || formValues.card_number.length < 19) {
            enqueueSnackbar('Please enter a valid card number', { variant: 'error' });
            return;
        }

        if (errors.card_expiration) {
            enqueueSnackbar('Please enter a valid expiration date', { variant: 'error' });
            return;
        }

        if (!formValues.card_cvv || formValues.card_cvv.length < 3) {
            enqueueSnackbar('Please enter a valid CVV', { variant: 'error' });
            return;
        }

        ShowLoader(true);
        await ApiRequest({
            url: "/payments/defaultCheckout",
            method: "post",
            body: {
                order_id: localStorage.getItem("order_id"),
                payment_method: payment_method,
                name: formValues.name,
                card_number: formValues.card_number,
                card_expiration: formValues.card_expiration,
                card_cvv: formValues.card_cvv,
            },
            setResponse: (response: any) => {
                console.log("res",response);
                if(response.status === "success") {
                    setPaymentStatus('success', "Payment successful");
                }
                else {
                    setPaymentStatus('error', "Payment failed");    
                }
            }
        });
        ShowLoader(false);
        
    }

    const [cssClassList, setCssClassList] = useState("w-input");
    const [buttonClassList, setButtonClassList] = useState("w-button");

    useEffect(() => {
        const inputElement = document.querySelector('[pc="fname"]');

        if (inputElement) {
            const classList = inputElement.classList.value;
            setCssClassList(classList);
        }

        const buttonElement = document.querySelector('[pc-step="accordion-next"]');
        if (buttonElement) {
            let classList = buttonElement.classList.value;
            classList = classList.replace("is-disabled", "").trim(); // Eliminar "is-disabled"
            setButtonClassList(classList);
        }

    } ,[]);



    return (
        <div className="form_wrapper">

        
            <div className="secure_payment_message">
                <TfiLock /> 
                <span>Payments are secure and encrypted</span>
            </div>
        
            <div className="input_group">
                <TextField 
                    placeholder="Name"
                    value={formValues.name}
                    onChange={(e) => setFormValues({ ...formValues, name: e.target.value })}
                    InputProps={{
                        startAdornment: (
                            <GoPerson 
                                size={25} 
                                style={{ 
                                    marginRight: 10, 
                                    opacity: 0.5
                                }} 
                            />
                        ),
                    }}
                    className={cssClassList}
                                    
                />

                <TextField 
                    placeholder="Card Number"
                    value={formValues.card_number}
                    onChange={(e) => setFormValues({ ...formValues, card_number: formatCardNumber(e.target.value) })}
                    inputProps={{ maxLength: 19 }}
                    InputProps={{
                        startAdornment: (
                            <CiCreditCard1 
                                size={25} 
                                style={{ 
                                    marginRight: 10, 
                                    opacity: 0.5
                                }} 
                            />
                        ),
                    }}
                    className={cssClassList}
                    
                />

                <div className="input_row">
                    <TextField 
                        placeholder="Expiration Date (MM/AA)"
                        value={formValues.card_expiration}
                        onChange={handleExpirationChange}
                        error={errors.card_expiration}
                        helperText={errors.card_expiration ? "Invalid date MM/AA" : ""}
                        className={cssClassList}
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <BsCalendar2Check 
                                    size={20} 
                                    style={{ 
                                        marginRight: 10, 
                                        opacity: 0.5
                                    }} 
                                />
                            ),
                        }}
                    />

                    <TextField 
                        placeholder="CVV"
                        value={formValues.card_cvv}
                        onChange={(e) => setFormValues({ ...formValues, card_cvv: e.target.value })}
                        inputProps={{ maxLength: 4 }}
                        className={cssClassList}
                        sx={{ height: "auto" }}
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <TfiLock 
                                    size={25} 
                                    style={{ 
                                        marginRight: 10, 
                                        opacity: 0.5
                                    }}
                                />
                            ),
                        }}
                    />
                </div>

                {payment_method === "test_mode" && (
                    <div className="test_mode">
                        <span className="test_mode_warning"><CiWarning />
                        Transaction In Test Mode</span>
                    </div>
                )}

                    <div className="payment_button_container">
                        <button 
                            className={buttonClassList}
                            onClick={submitPayment}
                            style={{width: "100%"}}
                        >
                            Place Order
                    </button>

                    </div>

           
                    

                
            </div>
        </div>
    );
}

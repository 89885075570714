import Bag from 'components/Bag/Bag';
import Cart from 'components/Cart/Cart';
import Checkout from 'components/Checkout/Checkout';
import ProductUtils from 'components/Products/ProductUtils';
import { useEffect, useState } from 'react';
import {BrowserRouter as Router , Route, Routes} from 'react-router-dom';

export default function AppRoutes(props: any) {
  const role_id = Number(localStorage.getItem('role_id'));
  const order_id = localStorage.getItem('order_id');

  const [productPage, setProductPage] = useState(false);

  useEffect(() => {
    const addToCartForm = document.querySelector("[pc='form']");
    if (addToCartForm) {
      console.log('Product page');
      setProductPage(true);
    }
  }, []);

  // Componente de redirección basado en window.location.href
  const RedirectTo = ({ url }: { url: string }) => {
    useEffect(() => {
      window.location.href = url;
    }, [url]);
    return null;
  };

  return (
    <>
      <Routes>
        {productPage && 
          <Route path="*" element={<ProductUtils />} />
        }
        <Route path="*" element={<Bag />} />
        <Route path="pennicart-cart" element={order_id ? <Cart /> : <RedirectTo url="/" />} />
        <Route path="pennicart-checkout" element={order_id ? <Checkout /> : <RedirectTo url="/" />} />
      </Routes>
    </>
  );
}

import { ApiRequest, ShowLoader } from 'GlobalFunctions';
import { useEffect } from 'react';
import GenerateVariants from './Variants';
import getDataAttributes from './Attributes';
import { showBag, getProductQuantity } from 'components/Bag/Bag';

export default function ProductUtils() {

    /*=========================================================
    READ PRODUCT DATA
    =========================================================*/
    useEffect(() => {
        const selectors = [
            { attribute: "product-name" },
            { attribute: "product-price" },
            { attribute: "product-quantity" },
            { attribute: "product-sku" },
            { attribute: "product-image", target: "src" },
            { attribute: "tax-type" },
            { attribute: "product-weight" },
            { attribute: "product-category" },
            { attribute: "bulk-tiered-discounts" },
            { attribute: "v1-select" },
            { attribute: "v2-select" },
        ];

        /*=============================================
        GET ALL INPUTS VALUES INSIDE ADD TO CART FORM 
        =============================================*/
        const addToCartForm = document.querySelector("[pc='form']");

        const getCustomInputs = (form: HTMLFormElement) => {
            // Get inputs from form elements excluding those with  attribute
            const customInputs = Array.from(form.elements)
                .filter((element: any) => !element.hasAttribute('pc'))
                .map((element: any) => {
                    let value;
                    if (element.type === 'checkbox') {
                        if (!element.checked) return null;
                        value = element.checked;
                    } else if (element.type === 'radio') {
                        if (element.checked) {
                            value = element.value;
                        } else {
                            return null;
                        }
                    } else {
                        value = element.value;
                    }
                    return {
                        name: element.name,
                        value: value
                    };
                })
                .filter(input => input !== null);
        
            // Capture elements with pc-custom attribute
            const pcCustomElements = Array.from(document.querySelectorAll('[pc-custom]'))
                .map((element) => {
                    const htmlElement = element as HTMLElement; // Ensure the element is treated as HTMLElement
                    const name = htmlElement.getAttribute('pc-custom');
                    const value = htmlElement.innerText.trim(); // The text inside the tag will be the value
                    return {
                        name: name,
                        value: value
                    };
                });
        
            const variantAttributes = getVariantAttributes();
        
            // Combine all inputs (form inputs + pc-custom elements + variant attributes)
            return [...customInputs, ...pcCustomElements, ...variantAttributes];
        };
        




        



        /*=============================================
        GET VARIANT ATTRIBUTES
        =============================================*/
        const getVariantAttributes = () => {
            const variantAttributes: { name: string, value: string }[] = [];
            const variantSelectors = ["v1-select", "v2-select"];

            variantSelectors.forEach((selector, index) => {
                const inputs = document.querySelectorAll(`[pc='${selector}']`) as NodeListOf<HTMLInputElement>;
                inputs.forEach((input, i) => {
                    const value = input.value;
                    const name = input.getAttribute('name') || `custom_${index + 1}_${i + 1}`;
                    variantAttributes.push({ name, value });
                });
            });

            return variantAttributes;
        }

        const validateVariants = () => {
            const variantSelectors = ["v1-select", "v2-select"];
            for (const selector of variantSelectors) {
                const inputs = document.querySelectorAll(`[pc='${selector}']`) as NodeListOf<HTMLInputElement>;
            }
            return true;
        }

        const addToCart = document.querySelector("[pc='add-btn']");
        if (addToCart) {
            addToCart.addEventListener("click", async () => {
                if (!validateVariants()) return;
                
                const productData = await getDataAttributes(selectors);
                if (addToCartForm) {
                    productData.custom_inputs = getCustomInputs(addToCartForm as HTMLFormElement);
                }
                productData.order_id = localStorage.getItem('order_id');
                console.log(productData);
                AddToCart(productData);
            });
        }
    }, []);

    GenerateVariants();

    return null;
}



/*=========================================================
ADD TO CART
=========================================================*/
const AddToCart = async (productData: any) => {
    
    ShowLoader(true);
    await ApiRequest({
        url: "/orders/addToCart",
        method: "post",
        body: {
            ...productData,
        },
        setResponse: async (response: any) => {
            localStorage.setItem('order_id', response.data.order_id);
            await getProductQuantity();
            showBag();
        },
    });
}


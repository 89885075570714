import { useEffect } from 'react';


export default function GenerateVariants() {
  CreateProductHiddenForm();
  useEffect(() => {
    

    const originalImageSrc = document.querySelector("[pc='product-image']")?.getAttribute("src");
  const originalPrice = document.querySelector("[pc='product-price']")?.textContent;

    
    /*=============================================
    HIDE v-wrapper
    =============================================*/
    document.querySelectorAll("[pc='v-wrapper']").forEach(el => {
      console.log(el);
      if (el instanceof HTMLElement) {
        el.style.display = 'none';
      }
    });


    // Hide out of stock message
    document.querySelectorAll(".product-out-of-stock").forEach(el => {
      if (el instanceof HTMLElement) {
        el.style.display = 'none';
      }
    });

    // Remove any conditionally hidden form elements
    document.querySelectorAll(".w-condition-invisible").forEach(el => {
      el.remove();
    });

    // Set default quantity attributes
    document.querySelectorAll('input[name="quantity"]').forEach(input => {
      if (input instanceof HTMLInputElement) {
        input.value = "1";
        input.min = "1";
      }
    });

    // Remove srcset attribute so image changes
    const productImage = document.getElementById("product-image");
    if (productImage instanceof HTMLImageElement) {
      productImage.removeAttribute("srcset");
    }

    const variantSelect1 = document.querySelector('[pc="v1-select"]') as HTMLSelectElement | null;
    const variantSelect2 = document.querySelector('[pc="v2-select"]') as HTMLSelectElement | null;
    if (!variantSelect1 && !variantSelect2) return;

    /*=============================================
    IF 2 VARIANTS EXIST
    =============================================*/
    if (variantSelect1 && variantSelect2) {
      variantSelect2.style.display = 'none';
      variantSelect2.disabled = true;

      /*=============================================
      FILL VARIANT OPTIONS
      =============================================*/
      document.querySelectorAll("[pc='v-item']").forEach(el => {
        const variant1Name = el.querySelector("[pc='v1-name']")?.textContent;
        if (variant1Name) {
          variantSelect1.innerHTML += `<option value="${variant1Name}">${variant1Name}</option>`;
        }
      });

      // Remove duplicate options
      const seen = new Set();
      const optionsHtml = Array.from(variantSelect1.options).filter(option => {
        const isSeen = seen.has(option.value);
        seen.add(option.value);
        return !isSeen;
      }).map(option => option.outerHTML).join('');

      variantSelect1.innerHTML = optionsHtml;

      /*=============================================
      VARIANT 1 SELECT TRIGGER
      =============================================*/
      variantSelect1.addEventListener('change', function () {
        const variant1Chosen = variantSelect1.value;


        if (variant1Chosen === '') {
          if (originalImageSrc) {
            document.querySelector("[pc='product-image']")?.setAttribute("src", originalImageSrc);
          }
          if (originalPrice) {
            document.querySelector("[pc='product-price']")!.innerHTML = originalPrice;
          }
          if (variantSelect2) {
            variantSelect2.style.display = 'none';
            variantSelect2.disabled = true;
          }
          return;
        }

        if (variant1Chosen !== '') {
          variantSelect2.style.display = 'block';
          variantSelect2.disabled = false;
        } else {
          variantSelect2.style.display = 'none';
          variantSelect2.disabled = true;
        }


        

        variantSelect2.innerHTML = '';
        document.querySelectorAll("[pc='v-item']").forEach(el => {
          const variant1Name = el.querySelector("[pc='v1-name']")?.textContent;
          const variant2Name = el.querySelector("[pc='v2-name']")?.textContent;
          const inventory = el.querySelector("[pc='v-stock']")?.textContent;
          const image = el.querySelector("[pc='v-image']")?.getAttribute("src");
          const price = el.querySelector("[pc='v-price']")?.textContent;

          if (variant1Chosen === variant1Name) {
            if (productImage) {
              productImage.setAttribute("src", image || '');
            }

            if (inventory && parseInt(inventory) > 0) {
              variantSelect2.innerHTML += `<option value="${variant2Name}">${variant2Name}</option>`;
            } else {
              variantSelect2.innerHTML += `<option value="${variant2Name}" disabled>${variant2Name}</option>`;
            }

            // Select the first option
            variantSelect2.selectedIndex = 0;
            variantSelect2.dispatchEvent(new Event('change'));
          }
        });
      });

      /*=============================================
      VARIANT 2 SELECT TRIGGER
      =============================================*/
      variantSelect2.addEventListener('change', function () {
        const variant1Chosen = variantSelect1.value;
        const variant2Chosen = variantSelect2.value;

        document.querySelectorAll("[pc='v-item']").forEach(el => {
          const variant1Name = el.querySelector("[pc='v1-name']")?.textContent;
          const variant2Name = el.querySelector("[pc='v2-name']")?.textContent;
          const price = el.querySelector("[pc='v-price']")?.textContent;
          const sku = el.querySelector("[pc='v-sku']")?.textContent;
          const image = el.querySelector("[pc='v-image']")?.getAttribute("src");
          const weight = el.querySelector("[pc='v-weight']")?.textContent;
          const inventory = el.querySelector("[pc='v-stock']")?.textContent;

          if (variant1Chosen === variant1Name && variant2Chosen === variant2Name) {
            const variant1Input = document.getElementById("variant-1-input") as HTMLInputElement;
            const variant2Input = document.getElementById("variant-2-input") as HTMLInputElement;
            const pcProductPrice = document.getElementById('pc_product_price') as HTMLInputElement;
            const pcProductCode = document.getElementById('pc_product_code') as HTMLInputElement;
            const pcProductWeight = document.getElementById('pc_product_weight') as HTMLInputElement;
            const pcProductQuantityMax = document.getElementById('pc_product_quantity_max') as HTMLInputElement;
            const pcProductQuantity = document.querySelector('input[name="quantity"]') as HTMLInputElement;

            if (variant1Input) variant1Input.value = variant1Chosen;
            if (variant2Input) variant2Input.value = variant2Chosen;
            if (pcProductPrice) pcProductPrice.value = price || '';
            if (pcProductCode) pcProductCode.value = sku || '';
            if (pcProductWeight) pcProductWeight.value = weight || '';
            if (pcProductQuantityMax) pcProductQuantityMax.value = inventory || '';
            if (pcProductQuantity) pcProductQuantity.setAttribute("max", inventory || '');
            if (pcProductQuantity) pcProductQuantity.value = '1';
            if (productImage) productImage.setAttribute("src", image || '');
            document.querySelector("[pc='product-price']")!.innerHTML = price || '';
          }
        });
      });
    }

    /*=============================================
    IF 1 VARIANT EXISTS
    =============================================*/
    if (variantSelect1 && !variantSelect2) {
      document.querySelectorAll("[pc='v-item']").forEach(el => {
        const variant1Name = el.querySelector("[pc='v1-name']")?.textContent;
        if (variant1Name) {
          console.log("variant1Name",variant1Name);
          variantSelect1.innerHTML += `<option value="${variant1Name}">${variant1Name}</option>`;
        }
      });

      // Remove duplicate options
      const seen = new Set();
      const optionsHtml = Array.from(variantSelect1.options).filter(option => {
        const isSeen = seen.has(option.value);
        seen.add(option.value);
        return !isSeen;
      }).map(option => option.outerHTML).join('');

      variantSelect1.innerHTML = optionsHtml;

      /*=============================================
      VARIANT 1 SELECT TRIGGER
      =============================================*/
      variantSelect1.addEventListener('change', function () {
        const variant1Chosen = variantSelect1.value;


        if (variant1Chosen === '') {
          if (originalImageSrc) {
            document.querySelector("[pc='product-image']")?.setAttribute("src", originalImageSrc);
          }
          if (originalPrice) {
            document.querySelector("[pc='product-price']")!.innerHTML = originalPrice;
          }
          return;
        }

        document.querySelectorAll("[pc='v-item']").forEach(el => {
          const variant1Name = el.querySelector("[pc='v1-name']")?.textContent;
          const price = el.querySelector("[pc='v-price']")?.textContent;
          const sku = el.querySelector("[pc='v-sku']")?.textContent;
          const image = el.querySelector("[pc='v-image']")?.getAttribute("src");
          const images = el.querySelector("[pc='v-images']");
          const weight = el.querySelector("[pc='v-weight']")?.textContent;
          const inventory = el.querySelector("[pc='v-stock']")?.textContent;

          if (variant1Chosen === variant1Name) {
            const variant1Input = document.getElementById("variant-1-input") as HTMLInputElement;
            const pcProductPrice = document.getElementById('pc_product_price') as HTMLInputElement;
            const pcProductCode = document.getElementById('pc_product_code') as HTMLInputElement;
            const pcProductWeight = document.getElementById('pc_product_weight') as HTMLInputElement;
            const pcProductQuantityMax = document.getElementById('pc_product_quantity_max') as HTMLInputElement;
            const pcProductQuantity = document.querySelector('input[name="quantity"]') as HTMLInputElement;

            if (variant1Input) variant1Input.value = variant1Chosen;
            if (pcProductPrice) pcProductPrice.value = price || '';
            if (pcProductCode) pcProductCode.value = sku || '';
            if (pcProductWeight) pcProductWeight.value = weight || '';
            if (pcProductQuantityMax) pcProductQuantityMax.value = inventory || '';
            if (pcProductQuantity) pcProductQuantity.setAttribute("max", inventory || '');
            if (pcProductQuantity) pcProductQuantity.value = '1';
            if (productImage) productImage.setAttribute("src", image || '');
            document.querySelector("[pc='product-price']")!.innerHTML = price || '';
            // document.querySelectorAll(".w-slider .w-slide:not(:first)").forEach(el => el.remove());
            // if (images) {
            //   images.querySelectorAll("img").forEach(img => {
            //     const imageSource = img.getAttribute("src");
            //     document.querySelector(".w-slider .w-slider-mask")!.innerHTML += `<div class="w-slide" style="transform: translateX(0px); opacity: 1;"><img src="${imageSource}"></div>`;
            //   });
            // }
          }
        });
      });
    }

    /*=============================================
    IF VARIANT 2 EXISTS
    =============================================*/
    if (!variantSelect1 && variantSelect2) {
      document.querySelectorAll("[pc='v-item']").forEach(el => {
        const variant2Name = el.querySelector("[pc='v2-name']")?.textContent;
        if (variant2Name) {
          variantSelect2.innerHTML += `<option value="${variant2Name}">${variant2Name}</option>`;
        }
      });

      // Remove duplicate options
      const seen = new Set();
      const optionsHtml = Array.from(variantSelect2.options).filter(option => {
        const isSeen = seen.has(option.value);
        seen.add(option.value);
        return !isSeen;
      }).map(option => option.outerHTML).join('');

      variantSelect2.innerHTML = optionsHtml;

      /*=============================================
      VARIANT 2 SELECT TRIGGER
      =============================================*/
      variantSelect2.addEventListener('change', function () {
        const variant2Chosen = variantSelect2.value;

        if (variant2Chosen === '') {
          if (originalImageSrc) {
            document.querySelector("[pc='product-image']")?.setAttribute("src", originalImageSrc);
          }
          if (originalPrice) {
            document.querySelector("[pc='product-price']")!.innerHTML = originalPrice;
          }
          return;
        }

        document.querySelectorAll("[pc='v-item']").forEach(el => {
          const variant2Name = el.querySelector("[pc='v2-name']")?.textContent;
          const price = el.querySelector("[pc='v-price']")?.textContent;
          const sku = el.querySelector("[pc='v-sku']")?.textContent;
          const image = el.querySelector("[pc='v-image']")?.getAttribute("src");
          const weight = el.querySelector("[pc='v-weight']")?.textContent;
          const inventory = el.querySelector("[pc='v-stock']")?.textContent;

          if (variant2Chosen === variant2Name) {
            const variant2Input = document.getElementById("variant-2-input") as HTMLInputElement;
            const pcProductPrice = document.getElementById('pc_product_price') as HTMLInputElement;
            const pcProductCode = document.getElementById('pc_product_code') as HTMLInputElement;
            const pcProductWeight = document.getElementById('pc_product_weight') as HTMLInputElement;
            const pcProductQuantityMax = document.getElementById('pc_product_quantity_max') as HTMLInputElement;
            const pcProductQuantity = document.querySelector('input[name="quantity"]') as HTMLInputElement;

            if (variant2Input) variant2Input.value = variant2Chosen;
            if (pcProductPrice) pcProductPrice.value = price || '';
            if (pcProductCode) pcProductCode.value = sku || '';
            if (pcProductWeight) pcProductWeight.value = weight || '';
            if (pcProductQuantityMax) pcProductQuantityMax.value = inventory || '';
            if (pcProductQuantity) pcProductQuantity.setAttribute("max", inventory || '');
            if (pcProductQuantity) pcProductQuantity.value = '1';
            if (productImage) productImage.setAttribute("src", image || '');
            document.getElementById("product-price")!.textContent = price || '';
          }
        });
      });
    }
  }, []);

  return null;
};




/*=========================================================
CREATE PRODUCT HIDDEN FORM
=========================================================*/
const CreateProductHiddenForm = () => {
  const form = document.createElement('form');
  form.id = 'pennicart_product_form';

  const inputs = [
      { id: 'pc_product_name', type: 'hidden' },
      { id: 'pc_product_price', type: 'hidden' },
      { id: 'pc_product_image', type: 'hidden' },
      { id: 'pc_product_weight', type: 'hidden' },
      { id: 'pc_product_quantity_max', type: 'hidden' },
      { id: 'pc_product_quantity', type: 'hidden' },
      { id: 'pc_product_code', type: 'hidden' },
      { id: 'pc_product_variant_1', type: 'hidden' },
      { id: 'pc_product_variant_2', type: 'hidden' },
      { id: 'pc_product_category', type: 'hidden' },
  ];

  inputs.forEach(input => {
      const inputElement = document.createElement('input');
      inputElement.type = input.type;
      inputElement.id = input.id;
      form.appendChild(inputElement);
  });

  document.body.appendChild(form);
}

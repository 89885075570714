export default function Loader (props: any) {

  const {
      loading = false
  } = props;



    const svg = `<svg version="1.1" id="logo_penni_loader" xmlns="http://www.w3.org/2000/svg" 
                    viewBox="0 0 78 25">
                    <path d="M18.2,7c0,4.9-3.8,8.4-9.1,8.4H5.8l-0.7,8.9c0,0.1-0.1,0.3-0.3,0.3H0.3c-0.2,0-0.3-0.1-0.3-0.3L2.3,0.3
                        C2.4,0.1,2.5,0,2.7,0h8.1C15.3,0,18.2,2.7,18.2,7z M9.8,4.6H7l-0.7,6.3H9c2.2,0,4-0.8,4-3.4C13.1,5.3,11.7,4.6,9.8,4.6z"/>
                    <path d="M33,17.9H21.4c0.5,1.8,2.2,2.7,4.3,2.7c1.4,0,2.7-0.3,3.9-1.2c0.1-0.1,0.3-0.1,0.4,0.1l1.6,2
                        c0.1,0.1,0.1,0.3,0,0.4c-1.5,1.6-3.8,2.8-6.7,2.8c-4.3,0-8.1-2.8-8.1-8c0-5.3,4.2-9.4,9.2-9.4c4.3,0,8.9,3,7.3,10.4
                        C33.3,17.8,33.1,17.9,33,17.9z M21.6,14.4h7.6c-0.2-2-1.8-2.8-3.6-2.8C23.6,11.5,22.2,12.7,21.6,14.4z"/>
                    <path d="M72.6,8h4.4c0.2,0,0.3,0.1,0.3,0.3l-1.7,16.1c0,0.1-0.1,0.3-0.3,0.3h-4.4c-0.2,0-0.3-0.1-0.3-0.3l1.7-16.1
                        C72.3,8.1,72.5,8,72.6,8z"/>
                    <path d="M75.3,5.3c1.5,0,2.7-1.2,2.7-2.7C78,1.2,76.8,0,75.3,0c-1.5,0-2.7,1.2-2.7,2.7C72.6,4.1,73.9,5.3,75.3,5.3z"/>
                    <path d="M37,8.1H41c0.2,0,0.4,0.2,0.4,0.5l-0.1,1.2c1.3-1.5,3.6-2.5,5.5-2.5c3.2,0,4.8,2.7,4.1,6.8l-0.7,4.4
                        c-0.1,0.8,0.1,1.2,1,1.2h0.3c0.2,0,0.4,0.2,0.4,0.5l-0.7,4.2c0,0.2-0.2,0.3-0.4,0.3h-0.6c-3.8,0-5.5-2-4.8-5.9l0.7-3.8
                        c0.3-1.7-0.3-3-1.9-3c-1.6,0-2.8,1.2-3.2,3.5l-0.9,9.2H35l1.6-16.2C36.6,8.2,36.8,8.1,37,8.1z"/>
                    <path d="M54.4,8.1h4.1c0.2,0,0.4,0.2,0.4,0.5l-0.1,1.2c1.3-1.5,3.6-2.5,5.5-2.5c3.2,0,4.8,2.7,4.1,6.8l-0.7,4.4
                        c-0.1,0.8,0.1,1.2,1,1.2h0.3c0.2,0,0.4,0.2,0.4,0.5l-0.7,4.2c0,0.2-0.2,0.3-0.4,0.3h-0.6c-3.8,0-5.5-2-4.8-5.9l0.7-3.8
                        c0.3-1.7-0.3-3-1.9-3c-1.6,0-2.8,1.2-3.2,3.5l-0.9,9.2h-4.9l1.6-16.2C54.1,8.2,54.3,8.1,54.4,8.1z"/>
                </svg>`;
  
      const loader = `<div class='box_loader_spinner'> ${svg} </div>`;

return (
  

    <div className="box_loader" dangerouslySetInnerHTML={{__html: loader}}></div>

);
}
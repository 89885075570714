export const ProjectConfig = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')?{
    api_url: "https://j3q5wm2x-3001.use.devtunnels.ms",
  
    STRIPE_PUBLIC_KEY: "pk_test_51NpZ4yJERu02kDeGMSwl6JVF5BpSByIHzzj3ON1Csk9ekckPViPeR9oLliIp94NjtRjthfzAy00ZAEMCvJeDFHC500aX22iQun",
    GMAP_API_KEY: "AIzaSyDXxN3BGzWBMDCH_k1bKKW2BtUjBeflAFw",
    /*============================================
    SQUARE
    =============================================*/
    SQUARE_CLIENT_ID: "sandbox-sq0idb-caUgu2AzZvJM3mQ0b4otBw",
    SQUARE_BASE_URL: "https://connect.squareupsandbox.com",
  }
  :
  {
    api_url: "https://pcapi.osabana.com",
    STRIPE_PUBLIC_KEY: "pk_test_51NpZ4yJERu02kDeGMSwl6JVF5BpSByIHzzj3ON1Csk9ekckPViPeR9oLliIp94NjtRjthfzAy00ZAEMCvJeDFHC500aX22iQun", 
    GMAP_API_KEY: "AIzaSyDXxN3BGzWBMDCH_k1bKKW2BtUjBeflAFw",
    /*============================================
    SQUARE
    =============================================*/
    SQUARE_CLIENT_ID: "sandbox-sq0idb-caUgu2AzZvJM3mQ0b4otBw",
    SQUARE_BASE_URL: "https://connect.squareup.com",
  }
  
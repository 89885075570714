import { ApiRequest } from "GlobalFunctions";

/*=========================================================
GET COUNTRIES
=========================================================*/
export async function getCountries() {
    await ApiRequest({
        url: '/countries/getCountries',
        method: 'GET',
        setResponse: (response: any) => {
            const countries = response;

            const billingCountry = document.querySelector('[pc="country"]');
            const country = document.querySelector('[pc="s-country"]');

            const fillCountrySelect = (selectElement: any) => {
                if (selectElement) {
                    countries.forEach((country: any) => {
                        const option = document.createElement('option');
                        option.value = country.country_id;
                        option.innerHTML = country.country_name;
                        selectElement.appendChild(option);
                    });
                    // Add listener to select element
                    selectElement.addEventListener('change', (event: Event) => {
                        const target = event.target as HTMLSelectElement;
                        const countryId = target.value;
                        clearStateSelect(selectElement); // Clear states when country changes
                        getCountryStates(countryId, selectElement);
                    });
                }
            };

            const clearStateSelect = (selectElement: HTMLSelectElement) => {
                const stateSelect = getStateSelect(selectElement);
                if (stateSelect) {
                    stateSelect.innerHTML = '<option value="">Select State</option>'; // Clear and add default option
                }
            };

            const getStateSelect = (selectElement: HTMLSelectElement) => {
                const pcValue = selectElement.getAttribute('pc');
                if (pcValue === "country") {
                    return document.querySelector('[pc="state"]');
                } else if (pcValue === "s-country") {
                    return document.querySelector('[pc="s-state"]');
                }
                return null;
            };
            

            fillCountrySelect(billingCountry);
            fillCountrySelect(country);
        }
    });
}

/*=========================================================
GET COUNTRY STATES
=========================================================*/
export async function getCountryStates(country_id: any, selectElement: HTMLSelectElement) {
    await ApiRequest({
        url: '/countries/getCountryStates',
        method: 'GET',
        query: {
            country_id
        },
        setResponse: async (response: any) => {
            console.log(response, 'response', country_id, 'country_id', selectElement, 'selectElement');
            const states = response;

            const stateSelect = getStateSelect(selectElement);
            if (stateSelect) {
                // Clear previous states and add default empty option
                stateSelect.innerHTML = '<option value="">Select State</option>';
                states.forEach((state: any) => {
                    const option = document.createElement('option');
                    option.value = state.state_id;
                    option.innerHTML = state.state_name;
                    stateSelect.appendChild(option);
                });
            }
        }
    });
}

/*=========================================================
HELPER FUNCTIONS
=========================================================*/
const getStateSelect = (selectElement: HTMLSelectElement) => {
    console.log(selectElement.getAttribute('pc'), 'selectElement', selectElement);
    if (selectElement.getAttribute('pc') === "country") {
        return document.querySelector('[pc="state"]');
    } else if (selectElement.getAttribute('pc') === "s-country") {
        return document.querySelector('[pc="s-state"]');
    }
    return null;
}

export default {
    getCountries
};

import { ApiRequest, ShowLoader } from 'GlobalFunctions';
import { useEffect, useState } from 'react';
import getOrderDetails from '../Orders/getOrderDetails';
import Coupons from '../Orders/Coupons';

export default function Cart() {

    const [cart, setCart] = useState<any>(null);
    useEffect(() => {
        getCart()
    }, []);

    

    const getCart = async () => {
        const cart = await getOrderDetails({page: "cart"});
        setCart(cart);
        
        const checkout_button = document.querySelector('[pc="view-checkout"]');
        if (checkout_button) {
            checkout_button.addEventListener("click", async (e) => {
                window.location.href = "/pennicart-checkout";
            }
            )
        }
    }

    return (
        <Coupons
            cart={cart}
            page="cart"
        />
    )
}


import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ShowLoader } from 'GlobalFunctions';
import { useSnackbar } from "notistack";
import { useEffect, useState } from 'react';


const CheckoutForm = (props: any) => {
    const { setPaymentStatus } = props;
    const stripe = useStripe();
    const elements = useElements();
    
    const handleClick = async () => {
        ShowLoader(true);

        if (!stripe || !elements) {
            console.error('Stripe or Elements not loaded.');
            ShowLoader(false);
            return;
        }

        const { error, paymentIntent } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: window.location.href,
            },
            redirect: 'if_required'
        });

        if (error) {
            setPaymentStatus('error', "Payment failed");
            
        } else if (paymentIntent) {
            if (paymentIntent.status === 'succeeded') {
                console.log('Payment succeeded');
            } else {
                console.log('Payment not completed: ', paymentIntent.status);
            }
            setPaymentStatus('success', "Payment successful");
        }

        ShowLoader(false);
    };


    const [cssClassList, setCssClassList] = useState("w-input");
    const [buttonClassList, setButtonClassList] = useState("w-button");

    useEffect(() => {
        const inputElement = document.querySelector('[pc="fname"]');

        if (inputElement) {
            const classList = inputElement.classList.value;
            setCssClassList(classList);
        }

        const buttonElement = document.querySelector('[pc-step="accordion-next"]');
        if (buttonElement) {
            let classList = buttonElement.classList.value;
            classList = classList.replace("is-disabled", "").trim(); // Eliminar "is-disabled"
            setButtonClassList(classList);
        }

    } ,[]);


    return (
        <div>
            <PaymentElement />
            <button 
                type="button" 
                className={buttonClassList}
                disabled={!stripe}
                onClick={handleClick}
                style={{ width: '100%', marginTop: '20px' }}
            >
                Place Order
            </button>
        </div>
    );
};

const StripeForm = (props: any) => {

    const appearance = {
        theme: 'flat' as 'stripe' | 'night' | 'flat', 
        labels: 'floating' as 'floating' | 'above',  
        variables: {
            fontFamily: 'Arial, sans-serif',
            spacingUnit: '4px',
            borderRadius: '5px',
        },
        rules: {
            '.Label': {
            },
            '.Input': {
                borderColor: '#ced4da',
            },
            '.Error': {
                color: '#dc3545',
            },
        },
    };
    const { 
        payment_settings: {
            public_key,
            client_secret: clientSecret
        }, 
        setPaymentStatus 
    } = props;

    console.log('clientSecret', clientSecret);
    console.log('publicKey', public_key);

    const stripePromise = loadStripe(public_key);


    return (
        <Elements stripe={stripePromise} options={{ clientSecret, appearance }}>
            <CheckoutForm setPaymentStatus={setPaymentStatus} />
        </Elements>
    );
};

export default StripeForm;
